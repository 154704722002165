import { render, staticRenderFns } from "./yw_visualization_useStatistics.vue?vue&type=template&id=1a98aa2e"
import script from "./yw_visualization_useStatistics.vue?vue&type=script&lang=js"
export * from "./yw_visualization_useStatistics.vue?vue&type=script&lang=js"
import style0 from "./yw_visualization_useStatistics.vue?vue&type=style&index=0&id=1a98aa2e&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports