<template>
  <!-- 运维 - 模板 - 使用模板统计 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">招生工具模板</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">使用模板统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">机构名称:</span>
              <el-input
                v-model="retrievalData.compName"
                type="text"
                size="small"
                class="unitNamewidth"
                placeholder="请输入"
                clearable
              />
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">行政区划:</span>
              <el-cascader
                clearable
                filterable
                v-model="retrievalData.areaId"
                :options="areatreeList"
                :props="propsarea"
                size="small"
              ></el-cascader>
            </div>
            <div title="创建时间" class="searchboxItem ci-full">
              <span class="itemLabel">创建时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="retrievalData.entryTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
           <div title="使用次数" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">使用次数:</span>
              <el-input
                v-model="retrievalData.useNumberStart"
                clearable
                @keyup.native="retrievalData.useNumberStart = zF.positiveInteger(retrievalData.useNumberStart)"
                placeholder="请输入"
                size="small"
                class="sjd"
              ></el-input>
              <span style="min-width: 2rem !important">至</span>
              <el-input
                v-model="retrievalData.useNumberStart"
                clearable
                @keyup.native="retrievalData.useNumberStart = zF.positiveInteger(retrievalData.useNumberStart)"
                placeholder="请输入"
                size="small"
                class="sjd"
              ></el-input>
            </div>
            <div title="收藏次数" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">收藏次数:</span>
              <el-input
                v-model="retrievalData.likeNumberStart"
                clearable
                @keyup.native="retrievalData.likeNumberStart = zF.positiveInteger(retrievalData.likeNumberStart)"
                placeholder="请输入"
                size="small"
                class="sjd"
              ></el-input>
              <span style="min-width: 2rem !important">至</span>
              <el-input
                v-model="retrievalData.likeNumberEnd"
                clearable
                @keyup.native="retrievalData.likeNumberEnd = zF.positiveInteger(retrievalData.likeNumberEnd)"
                placeholder="请输入"
                size="small"
                class="sjd"
              ></el-input>
            </div>
            <div title="报名人数" class="searchboxItem ci-full">
              <span class="itemLabel">报名人数:</span>
              <el-input
                v-model="retrievalData.applyNumberStart"
                clearable
                @keyup.native="retrievalData.applyNumberStart = zF.positiveInteger(retrievalData.applyNumberStart)"
                placeholder="请输入"
                size="small"
                class="sjd"
              ></el-input>
              <span style="min-width: 2rem !important">至</span>
              <el-input
                v-model="retrievalData.applyNumberEnd"
                clearable
                @keyup.native="retrievalData.applyNumberEnd = zF.positiveInteger(retrievalData.applyNumberEnd)"
                placeholder="请输入"
                size="small"
                class="sjd"
              ></el-input>
            </div>
           
          </div>
          <div class="searchbox">
             <div title="直接使用次数" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">直接使用次数:</span>
              <el-input
                v-model="retrievalData.directUseNumberStart"
                clearable
                @keyup.native="retrievalData.directUseNumberStart = zF.positiveInteger(retrievalData.directUseNumberStart)"
                placeholder="请输入"
                size="small"
                class="sjd"
              ></el-input>
              <span style="min-width: 2rem !important">至</span>
              <el-input
                v-model="retrievalData.directUseNumberEnd"
                clearable
                @keyup.native="retrievalData.directUseNumberEnd = zF.positiveInteger(retrievalData.directUseNumberEnd)"
                placeholder="请输入"
                size="small"
                class="sjd"
              ></el-input>
            </div>
            <div title="修改使用次数" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">修改使用次数:</span>
              <el-input
                v-model="retrievalData.modifyUseNumberStart"
                clearable
                @keyup.native="retrievalData.modifyUseNumberStart = zF.positiveInteger(retrievalData.modifyUseNumberStart)"
                placeholder="请输入"
                size="small"
                class="sjd"
              ></el-input>
              <span style="min-width: 2rem !important">至</span>
              <el-input
                v-model="retrievalData.modifyUseNumberStart"
                clearable
                @keyup.native="retrievalData.modifyUseNumberStart = zF.positiveInteger(retrievalData.modifyUseNumberStart)"
                placeholder="请输入"
                size="small"
                class="sjd"
              ></el-input>
            </div>
             <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader" 
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="机构名称"
                align="left"
                prop="compName"
                show-overflow-tooltip
                min-width="240"
              />
              <el-table-column
                label="所属行政区划"
                align="left"
                prop="areaNamePath"
                show-overflow-tooltip
              />
              <el-table-column
                label="使用次数"
                align="left"
                prop="useNumber"
                show-overflow-tooltip
              />
              <el-table-column
                label="直接使用次数"
                align="left"
                prop="directUseNumber"
                show-overflow-tooltip
              />
              <el-table-column
                label="修改使用次数"
                align="left"
                prop="modifyUseNumber"
                show-overflow-tooltip
              />
              <el-table-column
                label="收藏次数"
                align="left"
                prop="likeNumber"
                show-overflow-tooltip
              />
              <el-table-column
                label="报名人数"
                align="left"
                prop="applyNum"
                show-overflow-tooltip
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "UnitList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 区划 - 数据
      areatreeList: [],
      // 区划 - 字段规则
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      // 检索数据
      retrievalData: {
        compName: "", // 机构名称
        areaId: "", // 行政区划
        entryTime: [], // 创建时间
        useNumberStart: "", // 使用次数 - 小
        useNumberEnd: "", // 使用次数 - 大
        directUseNumberStart: "", // 直接使用次数 - 小
        directUseNumberEnd: "", // 直接使用次数 - 大
        modifyUseNumberStart: "", // 修改使用次数 - 小
        modifyUseNumberEnd: "", // 修改使用次数 - 大
        likeNumberStart: "", // 收藏次数 - 小
        modifyUseNumberEnd: "", // 收藏次数 - 大
        applyNumberStart: "", // 报名人数 - 小
        applyNumberEnd: "", // 报名人数 - 大
      },
    };
  },
  created() {
    this.getareatree();
  },
  computed: {},
  methods: {
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      // 机构名称
      if (this.retrievalData.compName) {
        params.compName = this.retrievalData.compName;
      }
      // 区划
      if (this.retrievalData.areaId) {
        params.areaId = this.retrievalData.areaId;
      }
      // 创建时间
      if (this.retrievalData.entryTime) {
        params.createTimeStart = this.retrievalData.entryTime[0];
        params.createTimeEnd = this.retrievalData.entryTime[1];
      }
      // 使用次数 
      if (
        (this.useNumberStart && !this.useNumberEnd) ||
        (!this.useNumberStart && this.useNumberEnd)
      ) {
        this.$message.warning("请补全直接使用次数区间");
        return false;
      }
      if (this.useNumberStart && this.useNumberEnd) {
        if (Number(this.useNumberStart) > Number(this.useNumberEnd)) {
          this.$message.warning("起始举报次数不能大于截止举报次数");
          return false;
        } else {
          params.useNumberStart = this.useNumberStart;
          params.useNumberEnd = this.useNumberEnd;
        }
      }
      // 直接使用次数 
      if (
        (this.directUseNumberStart && !this.directUseNumberEnd) ||
        (!this.directUseNumberStart && this.directUseNumberEnd)
      ) {
        this.$message.warning("请补全直接使用次数区间");
        return false;
      }
      if (this.directUseNumberStart && this.directUseNumberEnd) {
        if (Number(this.directUseNumberStart) > Number(this.directUseNumberEnd)) {
          this.$message.warning("起始举报次数不能大于截止举报次数");
          return false;
        } else {
          params.directUseNumberStart = this.directUseNumberStart;
          params.directUseNumberEnd = this.directUseNumberEnd;
        }
      }
      // 修改使用次数 
      if (
        (this.modifyUseNumberStart && !this.modifyUseNumberEnd) ||
        (!this.modifyUseNumberStart && this.modifyUseNumberEnd)
      ) {
        this.$message.warning("请补全直接使用次数区间");
        return false;
      }
      if (this.modifyUseNumberStart && this.modifyUseNumberEnd) {
        if (Number(this.modifyUseNumberStart) > Number(this.modifyUseNumberEnd)) {
          this.$message.warning("起始举报次数不能大于截止举报次数");
          return false;
        } else {
          params.modifyUseNumberStart = this.modifyUseNumberStart;
          params.modifyUseNumberEnd = this.modifyUseNumberEnd;
        }
      }
      // 收藏次数 
      if (
        (this.likeNumberStart && !this.likeNumberEnd) ||
        (!this.likeNumberStart && this.likeNumberEnd)
      ) {
        this.$message.warning("请补全直接使用次数区间");
        return false;
      }
      if (this.likeNumberStart && this.likeNumberEnd) {
        if (Number(this.likeNumberStart) > Number(this.likeNumberEnd)) {
          this.$message.warning("起始举报次数不能大于截止举报次数");
          return false;
        } else {
          params.likeNumberStart = this.likeNumberStart;
          params.likeNumberEnd = this.likeNumberEnd;
        }
      }
      // 报名人数 
      if (
        (this.applyNumberStart && !this.applyNumberEnd) ||
        (!this.applyNumberStart && this.applyNumberEnd)
      ) {
        this.$message.warning("请补全直接使用次数区间");
        return false;
      }
      if (this.applyNumberStart && this.applyNumberEnd) {
        if (Number(this.applyNumberStart) > Number(this.applyNumberEnd)) {
          this.$message.warning("起始举报次数不能大于截止举报次数");
          return false;
        } else {
          params.applyNumberStart = this.applyNumberStart;
          params.applyNumberEnd = this.applyNumberEnd;
        }
      }
      this.doFetch({
        url: "/design/run/govUseStatistics/pageList",
        params,
        pageNum,
      });
    },
    // 字典 - 区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
  },
  watch: {},
};
</script>
<style lang="less">
.sjd {
  width: 5.2rem;
  input {
    border: none;
    border-bottom: 1px solid #dcdfe6;
    border-radius: 0;
  }
}
</style>